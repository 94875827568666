<!-- //
文章管理-详情
@auth xuyd
@date 2022-09-10
//-->
<template>
  <ContentEdit :content-type="4"/>
</template>
<script>
  import ContentEdit from '../common/article-detail'

  export default {
    name: 'ProductionDetail',
    components: {ContentEdit},
    data() {
      return {};
    },
    methods: {}
  }
</script>

<style lang="less" scoped>
</style>
